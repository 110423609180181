import {
  ShareIcon,
  BookmarkIcon,
  DesktopComputerIcon
} from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import copy from 'copy-to-clipboard';
import { Grid } from './components/Grid'
import { WordField } from './components/WordField'
//import { copyShareMsg } from './lib/share'
import { Alert } from './lib/alert'
import './App.css'


function setFocus(focusId: string) {
  document.getElementById(focusId)?.focus();
}

function App() {
  const title = "5 letter words | quickly find answers for Wordle";
  const description = "List of all 5 letter words for Wordle, Our 5 Letter Word Finder will help you get the unlimited word list. 5 letter word begin with, 5 letter word end with or containing letters of your choice."
  useEffect(() => {
    document.title = title;
    document.getElementsByTagName("meta")[3].content = description;
  }, []);
  const copyShareMsg = () => {
    copy(description + "\n" + document.location.href);
    setIsShared(true);
    setTimeout(setIsShared, 2000, false);
  }
  const [isShared, setIsShared] = useState(() => {
    console.log(1)
    return false
  })
  const wordLength = 5;
  let letters = []
  for (let i=0; i< wordLength; i++){
    letters.push('')
  }
  const [inputValueArray, setInputValueArray] = useState(letters);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let idIndex = parseInt(e.target.id.split('-')[2]);
    let inputValue = e.target.value;
    let rep = /[a-zA-Z]/;
    let focusId: string;
    if (rep.test(inputValue)) {
      if(idIndex < wordLength) {
        focusId = "input-letter-" + (idIndex + 1).toString();
        document.getElementById(focusId)?.focus();
      }
    } else {
      e.target.value = '';
    }
    let letterArray = [...inputValueArray];
    letterArray[idIndex] = e.target.value;
    setInputValueArray(letterArray);
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const element = e.currentTarget as HTMLInputElement;
    let idIndex = parseInt(element.id.split('-')[2]);
    let focusId: string;
    let move = '';
    let letterArray = [...inputValueArray];
    if (e.code === 'Backspace'){
      if (letterArray[idIndex] === '') {
        move = 'prev';
      }
      element.value = '';
      letterArray[idIndex] = element.value;
      setInputValueArray(letterArray);
    } else if (e.code === 'Delete'){
      element.value = '';
      letterArray[idIndex] = '';
    } else if (e.code === 'ArrowLeft'){
      move = 'prev';
    }else if (e.code === 'ArrowRight'){
      move = 'next';
    }else if (e.code.length === 4 && e.code.startsWith('Key')){
      let letter = e.code.slice(-1);
      element.value = letter;
      letterArray[idIndex] = letter;
      move = 'next';
    }
    if (move === 'next' && idIndex < wordLength) {
      focusId = "input-letter-" + (idIndex + 1).toString();
      setTimeout(setFocus, 10, focusId)
      //document.getElementById(focusId)?.focus();
    }else if (move === 'prev' && idIndex > 0) {
      focusId = "input-letter-" + (idIndex - 1).toString();
      setTimeout(setFocus, 10, focusId)
    } else {
      return
    }
    letterArray[idIndex] = element.value;
    setInputValueArray(letterArray);
  }
  
  return (
    <div className="text-center pt-4 pb-8 mx-auto px-6">
      <div className="flex pt-2 pb-8 max-w-7xl mx-auto px-2 pr-0 title-bar">
        <h1 className="text-2xl grow font-bold dark:text-white pl-4 text-center">
          5 Le<span>tt</span>er Words
        </h1>
        {/* <DesktopComputerIcon
          className="h-5 w-5 mr-2 cursor-pointer desktop-icon"
          onClick={() => eval("install()")}
        /> */}
        {/* <BookmarkIcon
          className="h-5` w-5 mr-2 cursor-pointer"
          onClick={() => window.}
        /> */}
        <ShareIcon
          className="h-5 w-5 mr-2 cursor-pointer"
          onClick={() => copyShareMsg()}
        />
      </div>

      <div className="pb-4 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <Grid
        wordLength={wordLength}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        />
      </div>

      <div className="pb-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
        <WordField
        inputValueArray={inputValueArray}
        />
      </div>
      <Alert
        message={'Link copied'}
        isOpen={isShared}
        variant={'success'}
      />
    </div>
  )
}

export default App
