//import * as React from "react";
import { WORDS_STR } from '../lib/words'

const words_dict = WORDS_STR.split(",");

const matchWords = (letters: string[]) => {
  let words: string[] = [];
  if(letters === ['', '', '', '', '']){
    return words;
  }
  //let rep = /{$letters[0]}.+/;
  let rep_str = letters.map((letter, i) => (letter || '.'));
  let rep = new RegExp(rep_str.join('').toLowerCase());
  for (let i=0; i<words_dict.length; i++){
    if(rep.test(words_dict[i])){
      words.push(words_dict[i]);
      if (words.length>1000){break}
    }
  }
  return words;
}

type ItemProps = {
  inputValueArray: string[]
  value: string,
}
export const WordItem = ({ inputValueArray, value }: ItemProps) => {
  let display_value = inputValueArray.map(function(letter, i){
    if (letter === '') {
      return value[i];
    } else {
      return <span key={i}>{value[i]}</span>
    }
  })
  return (
    <div className="w-24 m-1 border-2 rounded-lg">
      {display_value}
    </div>
  )
}

type FieldProps = {
  inputValueArray: string[]
}

export const WordField = ({ inputValueArray }: FieldProps) => {
  let words = matchWords(inputValueArray);
  let itemArray = [];
  if (inputValueArray){
    for (let i=0; i<words.length; i++){
      itemArray.push(
        <WordItem key={i}
        value={words[i]}
        inputValueArray={inputValueArray}
        />
      );
    }
  }
  return (
    <div className="h-48 justify-center flex flex-wrap content-start word-item">
      {itemArray.map((item, i) => item)}
    </div>
  )
}