import * as React from "react";

type gridProp = {
  wordLength: number,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void,
}

export const Grid = ({ wordLength, handleChange, handleKeyDown }: gridProp) => {
  const inputArray = [];
  //const inputValueArray: string[] = [];

/*   const handleChange: React.ChangeEvent<HTMLInputElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  } */

  for (let i=0; i<wordLength; i++){
    inputArray.push(
      <input className="text-center border-slate-200 text-3xl w-14 h-14 m-1 border-2 rounded-xl uppercase letter-input"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      maxLength={1}
      autoFocus={i === 0}
      id={"input-letter-" + i}
      key={"input-letter-" + i}
      autoComplete="off"
      onClick = { () => document.execCommand("selectall", false) }
      onFocus = { () => document.execCommand("selectall", false) }
      />
    );
    //inputValueArray.push('');
  }

  return (
    <div className="flex pb-6 justify-center">
      {inputArray.map((input, i) => input)}
    </div>
  )
}
